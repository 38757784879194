.loader_wrapper span svg {
  height: 20px;
  width: 40px;
}
.react-datepicker-wrapper{
  width: 100%;
}
.form-group-gap .form-group label {
  display: flex;
  align-items: baseline;
  gap: 6px;
}
.editPatient .react-datepicker-popper {
  z-index: 9;
}