.patientListFooter {
    overflow-x: auto;
    display: block;
    width: 100%;
}
.patientListFooter ul{
   margin: 0;
   padding: 0;
   display: flex;
   align-items: center;
   margin-bottom: 6px;
}
.patientListFooter ul li{
    margin: 0;
    padding: 0;
    list-style: none;
 }
 .patientListFooter ul li .MuiButton-root {
    border-radius: 0;
    border-left: solid 2px #f0f2f5;
    white-space: nowrap;
 }
 .patientListFooter ul li .MuiButton-root.isActive {
    border-bottom: 2px solid #1A73E8;
    color: #1A73E8;
 }
 .patientListFooter ul li .MuiButton-root:focus {
    box-shadow: none;
 }
 .patientListFooter ul li .MuiButton-root.addBtn {
    margin-left: 20px;
    padding: 4px;
    width: 44px;
    min-width: auto;
 }
 .patientListFooter ul li .MuiButton-root.addBtn svg {
    transform: scale(1.5);
 }
 /* Scrollbar Track */
 .patientListFooter::-webkit-scrollbar {
    width: 12px;
    height: 10px;
  }
  
  /* Scrollbar Thumb */
  .patientListFooter::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
  
  .patientListAction{
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 5px;
  }