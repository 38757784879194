.specialization-dropdown {
  position: absolute;
  max-height: 150px; /* Adjust this value to reduce height */
  overflow-y: auto; /* Enable vertical scroll when content overflows */
  background-color: white;
  border: 1px solid #ccc;
  width: 10%;
  height: 30%;
  z-index: 100;
}

.specialization-dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.specialization-dropdown-item:hover {
  background-color: #f0f0f0;
}

.inputLabel-text {
  font-size: 15px;
  padding-bottom: 20px;
  margin-top: -8px;
}

.MuiSelect-icon {
  color: #000; /* Ensure the icon is visible */
  right: 8px;
}

.Specialization_Selector {
  position: relative;
}

.dropdown_icon {
  position: absolute;
  right: 10px;
  top: 11px;
}

.Specialization_Selector .MuiInputBase-root.MuiInput-root #mui-component-select-specialization {
  padding-left: 0 !important;
}
