body {
    font-family: Arial, sans-serif;
    margin: 10px;
  }
  
  .container {
    /* max-width: 700px; */
    margin: auto;
    font-size: 12px; 
  }
  
  .wrapper {
    width: 90%;
    margin: auto;
    
  }
  
  h1,
  h2 {
    text-align: center;
    font-size: 16px; 
  }
  
  h3 {
    font-size: 14px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 30px; 
  }
  
  p {
    text-align: justify;
    line-height: 1.4; 
  }
  
  .signature-section {
    margin-top: 30px; 
  }
  
  .signature-section p {
    margin-bottom: 5px;
  }
  
  .signature-section span {
    display: inline-block;
    width: 120px; 
    border-bottom: 1px solid #000;
  }
  
  @media print {
    body {
      margin: 0;
    }  
    .container {
      margin: 0;
    }
  }
  