.form-container {
    max-width: 800px;
    margin: 0 35px;
    /* padding: unset; */
    /* border: 1px solid #ddd; */
    border-radius: 10px;
    /* background-color: #f7f7f7; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* height: 90vh; */
    /* overflow-y: auto; */
    font-family: 'Arial', sans-serif;
    height: 100%;
  }

  .form-section {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  .form-section:last-child {
    border: none;
  }

  .form-section h3, .form-section h4 {
    margin-bottom: 15px;
    color: #555;
    font-weight: 600;
  }