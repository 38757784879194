  .form-container {
    max-width: 800px;
    margin: 0 auto;
    /* padding: unset; */
    /* border: 1px solid #ddd; */
    border-radius: 10px;
    /* background-color: #f7f7f7; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* height: 90vh; */
    /* overflow-y: auto; */
    font-family: 'Arial', sans-serif;
    height: 100%;
  }

  .form-section {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  .form-section:last-child {
    border: none;
  }

  .form-section h3, .form-section h4 {
    margin-bottom: 15px;
    color: #555;
    font-weight: 600;
  }

  .form-group {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
  }

  .form-group label {
    margin: 0;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
  .form-group label input {
    margin-top: 5px;
  }

  .form-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
  }

  .add-button, .remove-button, .submit-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .add-button, .remove-button {
    background-color: #28a745;
  }

  .remove-button {
    background-color: #dc3545;
  }

  .submit-button {
    width: 100%;
    background-color: #007bff;
    transition: background-color 0.3s ease;
  }

  .submit-button:hover {
    background-color: #0056b3;
  }

  .form-subsection {
    margin-bottom: 15px;
    padding-left: 15px;
    border-left: 3px solid #007bff;
  }

  textarea.form-field {
    resize: vertical;
  }

  select.form-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
  }

  @media (max-width: 768px) {
    .form-group {
      grid-template-columns: 1fr;
      gap: 10px;
    }
    
    .form-group .form-field {
      grid-column: 1 / 2;
    }
    
    .submit-button {
      width: 100%;
    }
  }
